<template>
    <div>
        <multiselect
            :placeholder="$t('select')"
            v-model="selected"
            :options="options"
            :class="validateError != '' ? 'box-border-color' : ''"
            @input="handleInput($event)"
            label="text"
            track-by="value"
            :multiple="false"
            :select-label="''"
            :selected-label="''"
            :deselect-label="''"
            :searchable="true"
        >
			<span slot="noOptions">
				{{ $t("no_options") }}
			</span>
            <span slot="noResult">
                {{ $t("no_result") }}
            </span>
        </multiselect>
        <span class="invalid-feedback-custom"
              v-show="validateError"
              v-html="validateError"
        />
    </div>
</template>

<script>
// Helpers
import translateNSelected from "@/helpers/translateNSelected";
import handleInput from "@/helpers/handleInput";
import setSelected from "@/helpers/setSelected";

// Services
import HousingRoom from "@/services/HousingRoomServices";

// Other
import qs from "qs";

export default {
    props: {
        value: {
            default: null,
        },
        validateError: {
            type: String,
            default: "",
        },
        housing_building_id:{
            type: Number,
            default: null
        }
    },
    data() {
        return {
            selected: null,
            options: []
        }
    },
    watch: {
        value: {
            handler: function (newValue) {
                this.selected = setSelected(newValue, this.options);
            }
        },
        housing_building_id:{
            handler: function () {
                this.clearOptions()
                this.getOptions()
            }

        }
    },
    created() {
        this.getOptions();
    },
    methods: {
        handleInput: handleInput,
        setSelected: setSelected,
        translateNSelected: translateNSelected,
        getOptions() {
            let config
            if (this.housing_building_id){
                config = {
                    params: {
                        filter:{
                          housing_building_id: this.housing_building_id
                        },
                        sort:'name',
                        limit:-1
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                };
            }else{
                config = {
                    params: {
                        sort:'name',
                        limit:-1
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                };
            }

            HousingRoom.getAll(config)
                .then((response) => {
                    const data = response.data.data;
                    data.map((item) => {
                        this.options.push({
                            value: item.id,
                            text:item.name,
                        });
                    });
                })
                .then(() => {
                    this.selected = setSelected(this.value, this.options);
                })
        },
        clearOptions() {
            this.selected = null;
            this.options = [];
        }
    }
}
</script>
